<template>
  <b-container>
    <b-row
      style="height: 100vh"
      class="justify-content-center align-items-center"
    >
      <b-col lg="4">
        <div class="w-100 text-center mb-4">
          <h4 class="mb-0 font-weight-bold text-primary">
            Create New Password
          </h4>
        </div>
        <b-card class="shadow-sm">
          <div v-if="isConfirmed">
            <user-form-security
              :credentials="credentials"
              design="stacked"
              @submitting="resetPassword"
              :error="error"
            >
              <template v-slot:footer>
                <p v-if="passwordUpdated">
                  Your password is updated successfully.
                </p>
                <b-button
                  v-if="!passwordUpdated"
                  type="submit"
                  variant="primary"
                  block
                  >Submit</b-button
                >
                <router-link
                  class="btn btn-primary btn-block"
                  v-if="passwordUpdated"
                  :to="{ name: 'AuthLogin' }"
                  >Return to login</router-link
                >
              </template>
            </user-form-security>
          </div>
          <div v-if="isExpired">
            <h4 class="text-center mb-3">Oops, link expired!</h4>
            <hr />
            <p v-if="resendLinkSuccess" class="text-center">
              <small>A new reset link is sent successfully.</small>
            </p>
            <p v-if="error" class="text-danger text-center">
              <small>{{ error }}</small>
            </p>
            <b-button @click="resendLink" variant="primary" block
              >Resend Reset Link</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'
  import UserFormSecurity from '@/components/UserFormSecurity'

  export default {
    name: 'AuthPassReset',

    components: {
      UserFormSecurity
    },

    data () {
      return {
        credentials: {
          newPassword: '',
          confirmPassword: ''
        },
        isConfirmed: false,
        isExpired: false,
        resendLinkSuccess: false,
        passwordUpdated: false,
        error: ''
      }
    },

    methods: {
      async confirmPassReset () {
        // Extract token
        const { token } = this.$route.params

        try {
          // Call api to confirm email
          // On email confirmation success
          await UserService.confirmPassReset(token)
          this.isConfirmed = true
        } catch (error) {
          // UserService.confirmPassReset fails
          this.isExpired = true
        }
      },

      async resendLink () {
        // Extract email
        const { email } = this.$route.params

        try {
          // Call api to resend link
          await UserService.resendPassResetLink(email)
          // Show resend link success message
          this.error = ''
          this.resendLinkSuccess = true
        } catch (error) {
          console.log(error)
          // Show error
          this.error = error.response.data.message
        }
      },

      async resetPassword (credentials) {
        const { email } = this.$route.params

        if (credentials.newPassword !== credentials.confirmPassword) {
          this.error = 'Password does not match.'
          return
        }

        try {
          await UserService.resetPassword({
            email,
            password: credentials.newPassword
          })

          this.error = ''
          this.passwordUpdated = true
        } catch (error) {
          this.error = error.response.data.message
        }
      }
    },

    created () {
      this.confirmPassReset()
    }
  }
</script>

<style></style>
